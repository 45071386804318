<template>
  <div class="record-page">
    <div class="image-empty" v-if="!recordList.length">
      <img src="
//download-cos.yofish.com/yofish-gongjushiyebu/20230308091644618-exchange-empty.png" alt="" />
      <p class="empty-desc">这里什么都没有</p>
    </div>
    <div class="recordContent2" v-else>
      <div v-for="(item, index) in recordList"
        :key="`${item.goodsId}_${index}`"
        class="recordList"
        :class="item.goodsType === '3' ? 'recordList1' : 'recordList2'"
        @click="clickAction(item)">
        <div class="recordListImg">
          <!-- <img :src="item.goodsExchangeRecordWlDto.thumbUrl"
            v-if="item.goodsExchangeRecordWlDto" alt="" />
          <img
            v-if="(!item.goodsExchangeRecordWlDto)
            && isGoodsId(item.goodsId)"
            :src="require(`./img/${item.goodsId}.png`)" alt=""> -->
          <img
            v-if="item.goodsPicUrl"
            :src="item.goodsPicUrl" alt="" />
            <img
            v-else
      src="//download-cos.yofish.com/yofish-gongjushiyebu/20230330134935483-no-image.png" alt="" />
        </div>
        <div class="recordListTitle">
          <div class="recordListTitleName">
            {{ item.goodsName }}
          </div>
          <div class="record-list-label">
            <span class="sign">有鱼定制</span>
            <span class="sign">{{ item.exchangeType === '1' ? '不包邮' : '包邮' }}</span>
          </div>
          <div class="recordListFooter"
            v-if="item.goodsType === '3'">{{ item.exchangeTime }}</div>
        </div>
        <div class="recordListRight">
          <span class='recordListRightBtn'></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAppStatus } from '@/utils/app';
import { getQueryExchangeRecord } from '@/api/base';
import { yinggeChannel, goodsIdList } from './js/goods';
import md5 from 'js-md5';

export default {
  name: 'GoldRecord',
  data() {
    return {
      recordList: [],
    };
  },
  computed: {
    cuserId() {
      // return '6d1455cb-cdbb-46c4-911d-8a60a0342fe9';
      return getAppStatus().cuserId;
    },
    yinggeUrl() {
      return 'https://songzhaopian.com/';
      // return 'https://pre.songzhaopian.com/';
    },
  },
  mounted() {
    document.title = '兑换记录';
    this.recordData();
  },
  methods: {
    async recordData() {
      let res;
      const { cuserId } = this;
      try {
        res = await getQueryExchangeRecord({ cuserId });
      } catch (e) {
        console.log(e);
        return this.$toast({
          content: e.desc,
          duration: 3000,
        });
      }
      if (res.code === 1) {
        this.recordList = res.results.records || [];
        this.recordList = this.recordList.filter((item) => item.goodsId.includes('YG-'));
      }
    },
    jumpAddress(data) {
      let thumbUrl = '';
      if (data.goodsExchangeRecordWlDto) {
        thumbUrl = data.goodsExchangeRecordWlDto.thumbUrl || '';
      }
      let { gerId, goodsId } = data;
      let { wid } = data.winnerAddress;
      if (!thumbUrl) {
        thumbUrl = data.goodsPicUrl;
      }
      this.$router.push({
        path: 'order',
        query: {
          thumbUrl,
          gerId,
          from: 'record',
          goodsId,
          wid,
        },
      });
    },
    jumpYgUrl(record) { // 跳转印鸽定制页面
      const { yinggeUrl, cuserId } = this;
      const { origin } = window.location;
      const { gerId, winnerAddress, goodsId } = record;
      const { wid } = winnerAddress;
      sessionStorage.setItem('wid', wid);
      sessionStorage.setItem('gerId', gerId);
      let url = origin + `/gold/order?gerId=${gerId}&goodsId=${goodsId}&wid=${wid}`;
      url = encodeURIComponent(url);
      window.open(yinggeUrl + 'reseller-youyujizhang/?outProductId='
      + goodsId + '&outUserId=' + cuserId + '&callback=' + url, '_self');
    },
    jumpNewYgUrl(record) { // 跳转印鸽新定制页面
      const { yinggeUrl } = this;
      let { goodsId, yinGeParam } = record;
      // let channel = yinggeChannel();
      // console.log(channel);
      // let channel = channel[goodsId];
      let openid = md5(this.cuserId);
      let tpFlag = md5(`yyjz:${openid}`);
      let gid = openid + tpFlag;
      window.open(`${yinggeUrl}order_list?gid=${gid}&tp_flag=yyjz`, '_self');
    },
    isObjectEmpty(data) {
      if (!data) return true;
      if (data) {
        return JSON.stringify(data) === '{}';
      }
    },
    jumpOrder(data) {
      console.log(data);
      let {
        thumbUrl, expressNo, companyCode, gerid,
      } = data?.goodsExchangeRecordWlDto || '';
      let { goodsId, winnerAddress } = data;
      winnerAddress = JSON.stringify(winnerAddress);
      sessionStorage.setItem('addressList', winnerAddress);
      if (!thumbUrl) {
        thumbUrl = data.goodsPicUrl;
      }
      console.log(gerid);
      this.$router.push({
        path: 'newOrder',
        query: {
          thumbUrl,
          goodsId,
          wayId: expressNo, // 快递号
          companyCode,
          gerId: gerid, // 订单号
        },
      });
    },
    isGoodsId(id) {
      return goodsIdList().includes(id);
    },
    isNewGoodsId(id) {
      return id.includes('FU-YG-E-');
    },
    clickAction(data) {
      let {
        goodsType, goodsExchangeRecordWlDto, winnerAddress, exchangeType,
      } = data;
      console.log(data);
      if (exchangeType === '2' && goodsType === '3') {
        // 去定制
        !goodsExchangeRecordWlDto && this.isObjectEmpty(goodsExchangeRecordWlDto)
          && this.jumpYgUrl(data);
        // 填写收货地址
        goodsExchangeRecordWlDto && (!winnerAddress || !winnerAddress.address)
          && this.jumpAddress(data);
        // 查看订单
        goodsExchangeRecordWlDto && winnerAddress && winnerAddress.address && this.jumpOrder(data);
      }
      exchangeType === '1' && this.jumpNewYgUrl(data); // 不包邮
    },
  },
  destroyed() {
  },
};
</script>
<style scoped lang="scss">
.image-empty {
  width: 400px;
  margin: auto;
  padding-top: 408px;
  img {
    width: 100%;
  }
}
.empty-desc {
  text-align: center;
  margin-top: 40px;
  font-size: 24px;
  font-weight: 400;
  color: rgba(51,51,51,0.6);
  line-height: 34px;
}
.record-page {
  max-width: 750px;
  padding-bottom: 40px;
  margin: auto;
}

.recordContent2 {
  height: 100%;
  padding-top: 15px;
  transition: all 0.3s ease-in-out;
  margin: 0 26px;
}

.recordList {
  background: #ffffff;
  margin-bottom: 20px;
  border-radius: 0.32rem;
  font-size: 0;
  display: flex;
  padding: 20px;
  align-items: center;
}
.recordListImg {
  width: 160px;
  height: 160px;
  flex: 0 0 auto;
  margin-right: 20px;
}
.recordListImg > img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
.record-list-label {
  margin-top: 8px;
  display: flex;
  .sign {
    font-size: 20px;
    font-weight: 400;
    color: #EF5C53;
    line-height: 28px;
    padding: 2px 20px;
    border-radius: 16px;
    border: 1PX solid #EF5C53;
    &:first-child {
      margin-right: 20px;
    }
  }
}
.recordListTitleName {
  font-size: 28px;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
}
.recordListFooter {
  margin-top: 46px;
  font-size: 24px;
  font-weight: 400;
  color: rgba(51,51,51,0.6);
  line-height: 34px;
}
.recordListRight {
  margin-left: auto;
}
.recordListRightBtn {
  display: block;
  width: 36px;
  height: 36px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image:
  url('//download-cos.yofish.com/yofish-gongjushiyebu/20230329150042684-right-arrow.png');
}
.recordListRightArrow {
  // text-align: right;
  // margin-top: .2rem;
}
.sureAddress {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.32rem;
  color: #777777;
  margin-right: 0.28rem;
}
.sureArrow {
  width: 0.213333rem;
  display: inline-block;
  vertical-align: middle;
}
.sureArrow img {
  width: 100%;
  height: auto;
  display: block;
}
.recordList2 .recordListTitle {
  width: 80%;
}
.recordList2 .recordListRight {
  width: 20%;
}
.recordList2 .recordListRightBtn,
.recordList2 .sureAddress {
  display: none;
}
</style>
